import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect } from 'react';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
import { useInView } from 'react-intersection-observer';
import { useCart } from '@magalu/mixer-graphql';
import { usePublisher } from '@magalu/mixer-publisher';
import { cartRedirect, routePush } from '@magalu/mixer-utils';
import { formatQuantity, getHighestPriorityTag, handleAddToCart, isCardWithButton } from './QuickClickCard.utils';
import { useInViewConfig } from './constants';
import { Button, CardContent, CardHeader, Container, Image, ImageContainer, ProductTitle, Price, BestPrice, PaymentMethodDescription, OfferTimerContainer, OfferTimer, ShippingDescription, ShippingTag, Title } from './QuickClickCard.styles';
var QuickClickCard = function QuickClickCard(_ref) {
  var button = _ref.button,
    cartExtensions = _ref.cartExtensions,
    cartUrl = _ref.cartUrl,
    clickEvent = _ref.clickEvent,
    designTokenId = _ref.designTokenId,
    image = _ref.image,
    location = _ref.location,
    minimumOrderQuantity = _ref.minimumOrderQuantity,
    path = _ref.path,
    price = _ref.price,
    product = _ref.product,
    productId = _ref.productId,
    productTitle = _ref.productTitle,
    restrictions = _ref.restrictions,
    salesChannelId = _ref.salesChannelId,
    sellerId = _ref.sellerId,
    shippingTag = _ref.shippingTag,
    title = _ref.title,
    viewEvent = _ref.viewEvent;
  var _useCart = useCart(),
    _useCart2 = _slicedToArray(_useCart, 1),
    addToCart = _useCart2[0].addToCart;
  var _useInView = useInView(useInViewConfig),
    _useInView2 = _slicedToArray(_useInView, 2),
    ref = _useInView2[0],
    inView = _useInView2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var bestPrice = price.bestPrice,
    paymentMethodDescription = price.paymentMethodDescription;
  var formattedBestPrice = currencyFormatter({
    value: bestPrice
  });
  var hasActionButton = isCardWithButton({
    button: button,
    designTokenId: designTokenId
  });
  var highestPriorityTag = getHighestPriorityTag(shippingTag);
  var timeEndDate = restrictions == null ? void 0 : restrictions.timer_end_date;
  var timeStartDate = restrictions == null ? void 0 : restrictions.timer_start_date;
  var handleClick = function handleClick(event) {
    event.preventDefault();
    clickEvent();
    if (!hasActionButton) {
      routePush({
        path: path,
        spa: true
      });
      return;
    }
    if (button.action.includes('view_cart')) {
      cartRedirect({
        cartUrl: cartUrl
      });
      return;
    }
    if (button.action.includes('add_item_to_cart')) {
      handleAddToCart({
        addToCart: addToCart,
        cartExtensions: cartExtensions,
        cartUrl: cartUrl,
        channel: salesChannelId,
        location: location,
        product: product,
        productId: productId,
        publish: publish,
        quantity: formatQuantity(minimumOrderQuantity),
        sellerId: sellerId
      });
    }
  };
  useEffect(function () {
    if (inView) viewEvent();
  }, [inView]);
  return React.createElement(Container, {
    "data-testid": "quickclickcard-".concat(productId),
    onClick: handleClick,
    role: "button",
    tabIndex: "0",
    ref: ref
  }, React.createElement(CardHeader, null, React.createElement(Title, {
    as: "h3"
  }, title), React.createElement(ImageContainer, null, React.createElement(Image, {
    src: image,
    lazy: true
  })), restrictions && React.createElement(OfferTimerContainer, {
    "data-testid": "quickclickcard-offertimer-container"
  }, React.createElement(OfferTimer, {
    restrictions: {
      timer_end_date: timeEndDate,
      timer_start_date: timeStartDate
    },
    text: "Termina em"
  }))), React.createElement(CardContent, null, React.createElement(ProductTitle, {
    "data-testid": "quickclickcard-product-title"
  }, productTitle), React.createElement(Price, null, React.createElement(BestPrice, null, formattedBestPrice), React.createElement(PaymentMethodDescription, null, paymentMethodDescription)), highestPriorityTag ? React.createElement(ShippingTag, null, React.createElement(ShippingDescription, {
    "data-testid": highestPriorityTag.testId
  }, highestPriorityTag.content)) : null, hasActionButton && React.createElement(Button, {
    "data-testid": "quickclickcard-button",
    full: true,
    tabIndex: "-1"
  }, button.label)));
};
process.env.NODE_ENV !== "production" ? QuickClickCard.propTypes = {
  button: shape({
    action: string.isRequired,
    label: string.isRequired
  }),
  cartExtensions: oneOfType([arrayOf(shape({})), bool]),
  cartUrl: shape({}).isRequired,
  clickEvent: func,
  designTokenId: string.isRequired,
  image: string.isRequired,
  location: shape({}),
  minimumOrderQuantity: string,
  path: string.isRequired,
  price: shape({
    bestPrice: string,
    paymentMethodDescription: string
  }),
  product: shape({}).isRequired,
  productId: string.isRequired,
  productTitle: string.isRequired,
  restrictions: shape({}),
  salesChannelId: string,
  sellerId: string.isRequired,
  shippingTag: shape({}),
  title: string.isRequired,
  viewEvent: func
} : void 0;
QuickClickCard.defaultProps = {
  button: null,
  cartExtensions: false,
  clickEvent: function clickEvent() {},
  location: null,
  minimumOrderQuantity: '1',
  price: null,
  restrictions: null,
  salesChannelId: '',
  shippingTag: {
    complement: null,
    cost: null,
    time: null
  },
  viewEvent: function viewEvent() {}
};
export default QuickClickCard;